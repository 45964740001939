import * as React from 'react';
import { Link } from 'react-router-dom';
import { Amplify } from 'aws-amplify';
import { confirmSignIn, signOut, signIn, type SignInInput } from 'aws-amplify/auth';
import { cognitoUserPoolsTokenProvider } from 'aws-amplify/auth/cognito';

import { FormProps, Form, Input, Button } from 'antd';
import { ArrowRightOutlined, UserOutlined, UnlockOutlined, LoadingOutlined, WarningOutlined } from '@ant-design/icons';

import QRCode from 'qrcode.react';
import axios, { type AxiosResponse } from 'axios';

// types
import { NextStep } from '../Amplify.ts';
import { FieldType, State } from './types.ts';

// configs
import UserPoolData from '../../Assets/config';
import { text } from '../../Assets/text.ts';

Amplify.configure({
    Auth: {
        Cognito: {
            userPoolClientId: UserPoolData.clientId,
            userPoolId: UserPoolData.userPoolId,
        },
    },
});

type Props = {};

class LoginContainer extends React.Component<Props, State> {
    state = {
        loading: false,
        QRCode: '',
        showQRCode: false,
        inputTokenOnly: false,
        cognitoUser: {
            username: '',
        },
        redirect: false,
        error: false,
        errorToken: false,
        errorRedirect: false,
    };

    handleSubmitMFA: FormProps<FieldType>['onFinish'] = async ({ confirmationCode }) => {
        this.setState({ error: false });

        if (confirmationCode)
            try {
                const confirmSignInResponse = await confirmSignIn({
                    challengeResponse: confirmationCode,
                });
                const { nextStep } = confirmSignInResponse;

                if (nextStep.signInStep === NextStep.DONE) {
                    this.setState({ loading: true });
                    this.handleRedirect();
                }
            } catch (e) {
                this.setState({ errorToken: true });
            }
    };

    handleSignIn: FormProps<FieldType>['onFinish'] = async (values) => {
        this.setState({ loading: true, error: false });

        try {
            const signInResponse = await signIn(values as SignInInput);
            const { nextStep } = signInResponse;

            // case: USER should create MFA TOTP setup with authenticator app
            if (nextStep.signInStep === NextStep.CONTINUE_SIGN_IN_WITH_TOTP_SETUP) {
                const authCode = nextStep.totpSetupDetails.getSetupUri(text.meta.appName);

                this.setState({
                    QRCode: authCode.href,
                    showQRCode: true,
                    loading: false,
                });
            }

            // case: USER has created MFA App connection
            if (nextStep.signInStep === NextStep.CONFIRM_SIGN_IN_WITH_TOTP_CODE) {
                this.setState({ showQRCode: true, loading: false, inputTokenOnly: true });
            }
        } catch (error) {
            this.setState({ error: true, loading: false });
        }
    };

    async handleRedirect() {
        const token = await cognitoUserPoolsTokenProvider.getTokens()!;
        const { idToken } = token!;

        if (idToken) {
            await axios
                .post(
                    `${UserPoolData.invokeUrl}/auth`,
                    {},
                    {
                        headers: {
                            'Content-Type': 'application/json',
                            Authorization: idToken?.toString(),
                        },
                    }
                )
                .then(({ data, status }: AxiosResponse) => {
                    if (status === 200 || status === 201) {
                        window.location.href = data.Message;
                    } else {
                        this.handleErrorRedirect();
                    }
                })
                .catch(() => {
                    this.handleErrorRedirect();
                });
        }
    }

    handleErrorRedirect() {
        this.setState({ errorRedirect: true });
    }

    render() {
        const { loading, redirect, error, errorToken, showQRCode, errorRedirect } = this.state;

        // to stop MFA process if failed
        signOut();

        return (
            <>
                {!showQRCode && (
                    <div className="formWrapper">
                        <h1>{text.login.headline}</h1>

                        {error && (
                            <p className="message">
                                <WarningOutlined /> {text.login.messages.error}
                            </p>
                        )}
                        {errorToken && (
                            <p className="message">
                                <WarningOutlined /> {text.login.messages.errorToken}
                            </p>
                        )}

                        <Form
                            name="login"
                            labelCol={{ span: 8 }}
                            wrapperCol={{ span: 16 }}
                            style={{ maxWidth: 600 }}
                            initialValues={{ remember: true }}
                            onFinish={this.handleSignIn}
                            onFinishFailed={() => {}}
                            autoComplete="off"
                        >
                            <Form.Item<FieldType>
                                label={text.login.user.label}
                                name="username"
                                rules={[
                                    {
                                        required: true,
                                        message: text.login.user.error,
                                    },
                                ]}
                            >
                                <Input
                                    prefix={<UserOutlined style={{ color: '#000000' }} />}
                                    placeholder={text.login.user.placeholder}
                                />
                            </Form.Item>

                            <Form.Item<FieldType>
                                label={text.login.password.label}
                                name="password"
                                rules={[
                                    {
                                        required: true,
                                        message: text.login.password.error,
                                    },
                                ]}
                            >
                                <Input.Password
                                    prefix={<UnlockOutlined style={{ color: '#000000' }} />}
                                    placeholder={text.login.password.placeholder}
                                />
                            </Form.Item>

                            <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                                <Button type="primary" htmlType="submit">
                                    {loading ? <LoadingOutlined style={{ fontSize: 24 }} /> : text.login.submit}
                                </Button>
                            </Form.Item>
                        </Form>

                        <p>
                            <ArrowRightOutlined />
                            &nbsp;
                            <Link to="/pw">{text.login.messages.changePassword}</Link>
                        </p>
                    </div>
                )}

                {showQRCode && (
                    <div className="formWrapper">
                        <h1>{text.token.headline}</h1>

                        {errorRedirect && (
                            <p className="message">
                                <WarningOutlined /> {text.login.messages.errorRedirect}
                                <br />
                                <br />
                                <Link to="/" onClick={() => window.location.reload()}>
                                    {text.login.messages.retry}
                                </Link>
                            </p>
                        )}

                        {!this.state.inputTokenOnly && (
                            <div className="qrCode">
                                <QRCode value={this.state.QRCode} />
                            </div>
                        )}
                        {!errorRedirect && (
                            <Form
                                name="token"
                                labelCol={{ span: 8 }}
                                wrapperCol={{ span: 16 }}
                                style={{ maxWidth: 600 }}
                                initialValues={{ remember: true }}
                                onFinish={this.handleSubmitMFA}
                                onFinishFailed={() => {}}
                                autoComplete="off"
                            >
                                <Form.Item<FieldType>
                                    label={text.token.field.label}
                                    name="confirmationCode"
                                    rules={[
                                        {
                                            required: true,
                                            message: text.token.field.error,
                                        },
                                    ]}
                                >
                                    <Input placeholder={text.token.field.placeholder} />
                                </Form.Item>

                                <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                                    <Button type="primary" htmlType="submit">
                                        {loading ? <LoadingOutlined style={{ fontSize: 24 }} /> : text.token.submit}
                                    </Button>
                                </Form.Item>
                            </Form>
                        )}
                    </div>
                )}
                {redirect && this.handleRedirect()}
            </>
        );
    }
}

export default LoginContainer;
