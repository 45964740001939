import * as React from 'react';
import { Amplify } from 'aws-amplify';
import {
    resetPassword,
    type ResetPasswordInput,
    confirmResetPassword,
    type ConfirmResetPasswordInput,
} from 'aws-amplify/auth';
import { Form, Input, Button } from 'antd';
import { MailOutlined, FileProtectOutlined, UnlockOutlined, LoadingOutlined } from '@ant-design/icons';

// types
import { NextStep } from '../Amplify.ts';
import { FieldType, State } from './types.ts';

// configs
import UserPoolData from '../../Assets/config.jsx';
import { text } from '../../Assets/text.ts';

Amplify.configure({
    Auth: {
        Cognito: {
            userPoolClientId: UserPoolData.clientId,
            userPoolId: UserPoolData.userPoolId,
        },
    },
});

type Props = any & {
    form: any;
};

class ResetPasswordContainer extends React.Component<Props, State> {
    state = {
        loading: false,
        resetPassword: false,
        resetSuccess: false,
    };

    async handleReset({ username }: ResetPasswordInput) {
        try {
            const resetPasswordResponse = await resetPassword({ username });
            const { nextStep } = resetPasswordResponse;

            if (nextStep.resetPasswordStep === NextStep.CONFIRM_RESET_PASSWORD_WITH_CODE) {
                this.setState({ resetPassword: true });
            }
        } catch (error) {
            console.log(error);
        }
    }

    async handleConfirmResetPassword({ username, confirmationCode, newPassword }: ConfirmResetPasswordInput) {
        try {
            await confirmResetPassword({ username, confirmationCode, newPassword });

            this.setState({ resetSuccess: true, resetPassword: false });
        } catch (error) {
            console.log(error);
        }
    }

    handleForm(values: ResetPasswordInput | ConfirmResetPasswordInput, resetPassword: boolean) {
        if (resetPassword) {
            this.handleConfirmResetPassword(values as ConfirmResetPasswordInput);
        } else {
            this.handleReset(values as ResetPasswordInput);
        }
    }

    render() {
        const { loading, resetPassword, resetSuccess } = this.state;

        return (
            <>
                <div className="formWrapper">
                    <h1>{text.password.headline}</h1>

                    {resetPassword && (
                        <p>
                            {text.password.messages.token}
                            <br />
                            <br />
                        </p>
                    )}
                    {!resetSuccess && (
                        <Form
                            name="password"
                            labelCol={{ span: 8 }}
                            wrapperCol={{ span: 16 }}
                            style={{ maxWidth: 600 }}
                            initialValues={{ remember: true }}
                            onFinish={(values) => this.handleForm(values, resetPassword)}
                            onFinishFailed={() => {}}
                            autoComplete="off"
                        >
                            <Form.Item<FieldType>
                                label={text.password.user.label}
                                name="username"
                                rules={[
                                    {
                                        required: true,
                                        message: text.password.user.error,
                                    },
                                ]}
                            >
                                <Input
                                    prefix={<UnlockOutlined style={{ color: '#000000' }} />}
                                    placeholder={text.password.user.placeholder}
                                />
                            </Form.Item>

                            {resetPassword && (
                                <>
                                    <Form.Item<FieldType>
                                        label={text.password.newPassword.label}
                                        name="newPassword"
                                        rules={[
                                            {
                                                required: true,
                                                message: text.password.newPassword.error,
                                            },
                                        ]}
                                    >
                                        <Input.Password
                                            prefix={<FileProtectOutlined style={{ color: '#000000' }} />}
                                            placeholder={text.password.newPassword.placeholder}
                                        />
                                    </Form.Item>
                                    <Form.Item<FieldType>
                                        label={text.password.token.label}
                                        name="confirmationCode"
                                        rules={[
                                            {
                                                required: true,
                                                message: text.password.token.error,
                                            },
                                        ]}
                                    >
                                        <Input
                                            prefix={<MailOutlined style={{ color: '#000000' }} />}
                                            placeholder={text.password.token.placeholder}
                                        />
                                    </Form.Item>
                                </>
                            )}

                            <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                                <Button type="primary" htmlType="submit">
                                    {loading ? <LoadingOutlined style={{ fontSize: 24 }} /> : text.password.submit}
                                </Button>
                            </Form.Item>
                        </Form>
                    )}

                    {resetSuccess && (
                        <p>
                            {text.password.messages.success}
                            <br />
                            <br />
                            <a href="/">{text.password.messages.href}</a>
                            <br />
                            <br />
                        </p>
                    )}
                </div>
            </>
        );
    }
}

export default ResetPasswordContainer;
